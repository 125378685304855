import _objectSpread from "E:/work/coding/ruoyi-vue-pro-master/defi/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import axios from "axios";
import NetworkConfig from '@/config/network';
import { mapActions } from "vuex";
export default {
  components: {},
  data: function data() {
    return {
      loading: false,
      finished: false,
      netWorkList: Object.values(NetworkConfig)
    };
  },
  created: function created() {},
  methods: _objectSpread(_objectSpread({}, mapActions(['setUserInfo'])), {}, {
    onRefresh: function onRefresh() {
      this.register();
    },
    register: function register() {
      var _this = this;

      var url = "/defi/site/register";
      var params = {
        address: this.currentAccount,
        chain: this.defaultNetworkConfig.chainName
      };
      axios.post(url, params).then(function (res) {
        _this.loading = false;

        _this.setUserInfo(res.data.data);
      }).catch(function (e) {
        _this.loading = false;
      });
    },
    filterStatus: function filterStatus(val) {
      if (val === 0) {
        return t('Pending');
      } else if (val === 1) {
        return t('Complete');
      } else if (val === 2) {
        return t('reject');
      }

      return "";
    },
    getTime: function getTime(date) {
      date = new Date(date);
      var year = date.getFullYear().toString().padStart(4, '0');
      var month = (date.getMonth() + 1).toString().padStart(2, '0');
      var day = date.getDate().toString().padStart(2, '0');
      var hours = date.getHours().toString().padStart(2, '0');
      var minutes = date.getMinutes().toString().padStart(2, '0');
      var seconds = date.getSeconds().toString().padStart(2, '0'); //const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

      var formattedDate = "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hours, ":").concat(minutes, ":").concat(seconds);
      return formattedDate;
    },
    goBack: function goBack() {
      window.history.go(-1);
    }
  }),
  computed: {
    defaultNetworkConfig: {
      get: function get() {
        return this.netWorkList.find(function (r) {
          return r.default;
        });
      }
    },
    currentAccount: function currentAccount() {
      var currentAccount = this.$store.state.currentAccount;
      return currentAccount;
    },
    userInfo: {
      get: function get() {
        return this.$store.state.userInfo;
      }
    }
  }
};